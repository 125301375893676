.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.full-width-container {
  flex-grow:1;
  display: flex; /* Optional: if you want its children to be flex items */
  flex-direction: column; 
  background-color: #F9FAFB;
}

.inner-container {
  width: 100%; /* Ensure it stretches to fill its parent */
}

.content-area {
  display: flex;
}

.left-panel {
  flex: 0 0 15%;
  background-color: #FFFFFF;
  min-height:100vh;
  border-right:2px solid #E5E7EB;
}

.right-panel {
  flex: 0 0 15%;
}

.header, .footer {
  width: 100%;
}

.text-white {
  color: white;
}

.text-black {
  color: black;
}

.app-container {
  display: flex;
}

.top-header {
  color: #1F477D;
}

.header-top {
  display: flex;
  align-items: center; /* Vertically center */
  justify-content: flex-start; /* Horizontally align to the left */
  padding-left: 2vw; /* Adjust as needed for spacing from the left edge */
  border-bottom:2px solid #E5E7EB;
}

.main-panel {
  display:flex;
  flex: 0 0 85%;
  border:'1px solid #E5E7EB';
}

.main-panel-header {
  display: flex;
  flex-direction: row; /* Stack them vertically */
  align-items: flex-start; /* Align items to the left */
  justify-content: space-between; /* Align items to the top */
  padding-left: 10px 30px 10px 30px; /* Adjust padding as needed */
}

.main-panel-content {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: left; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  align-items: flex-start;
  /* min-width: 50vw; */
  width: 100%;
  box-sizing: border-box; /* Ensures padding and border are included in the width */

}

.header-container, .datagrid {
  width: 90%; /* Set the width to 90% of the screen width */
  margin: 0 auto; /* Center the divs horizontally */
  display: flex; /* Use flexbox to align items */
  flex-direction: column; /* Stack children vertically */
}

.secondary-text {
  text-align: left;
  color: #6B7280;
}

.new-document-button {
  cursor: pointer; /* Optional: Changes the cursor on hover */
  background-color: #1F477D;
  border-radius: 5px;
  padding:0px 10px 0px 10px;
  margin-top:2vh;
}

.modal-background {
  position: fixed; /* Use fixed or absolute positioning */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centers the modal */
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensures the modal is above other content */
  width: 80%;
  height: 80%;
}

.datagrid {
  align-self:center;
  /* max-height:75vh; */
  padding-left: 10px;
  padding-right: 10px;
  width:90%;
}

.template-datagrid {
  align-self:center;
  max-height:75vh;
  width:100%;
}

.table-header {
  font-weight: bold;
}

nav {
  display: flex;
  flex-direction: column; /* Stack links vertically */
}

nav a {
  color: black; /* Default color for links */
  text-decoration: none; /* Optional: Removes underline from links */
  padding: 8px 0; /* Optional: Adds some padding for better spacing */
}

nav a:hover {
  color: grey; /* Color on hover */
}

.nav-logo {
  width:20px;
  height:20px;
  margin:2px;
  justify-self: center;
  align-self:center;
  margin-right:5px;
}

nav a {
  display: flex;
  align-items: center; /* This ensures vertical centering */
  text-decoration: none; /* Optional: removes underline from links */
  color: inherit;
  padding-left: 2vw;
  margin: 2px 10px 2px 0px;
}

.active {
  background-color: #F9FAFB; /* Active link color */
  border-radius:10px;

}

.folder-box {
  display: flex;
  flex-direction: column;
  padding: 10px; /* Adds padding inside the folder-box */
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Adjusted for a lighter shadow */
  width: 100%;
  overflow: scroll;
  max-height:80vh;
  background-color: #FFFFFF;
  margin-top: 20px;
}

.folder-header {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Centers items vertically */
  padding: 0 10px; /* Adds padding on the left and right */
}

.folder-info {
  padding: 10px; /* Adds padding around the folder description */
  align-items: flex-start;
}

.add-document-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin:0;
  padding: 2px 10px 2px 10px;
  border: 1px solid #D1D5DB;
  cursor: pointer; 
  border-radius: 5px;
}

.add-document-button p {
  margin: 0; 
  padding:2px;

}

.document-card {
  display:flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid grey;
  padding:10px;

}

.modal-content {
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 100%; 
  justify-content: flex-start;
}

.button-list {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* This will make the buttons stretch to the same width */
  gap: 10px; /* Adjust the gap between buttons as needed */
  padding:20px;
  max-height:30vw;
  overflow-y:scroll;
}

.document-type-button {
  width: 20vw; /* Makes each button fill the container width */
  height: 10vw; /* Example height, adjust as needed */
  background-color:white;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; /* Adds some space between the input fields */
}

/* Optional: Center the entire form or input area */
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width:60%;
}


.input-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-bottom: 20px; /* Adds some space between the input fields */
  cursor: pointer;
  
  padding:10px;
}

.dashed-border {
  border: 2px dashed #1F477D;
}

.webviewContainer {
  display:flex;
  flex-grow:100;
}

.MyComponent {
  display:flex;
  flex-grow:100;
}

.webviewer {
  display:flex;
  flex-grow:100;
  margin-left: -20px;
}


.tabs {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background: #f0f0f0;
  border-bottom: 1px solid #ddd;
}

.tabs button {
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
}

.tabs button.active {
  border-bottom: 2px solid #1f477d;
  font-weight: bold;
  border-radius: 0;
}

.tab-content {
  padding: 20px;
}

.template-tab-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Adjusts components to the bottom */
}

.dropdown-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Adjust the gap between items */
  box-shadow: 0 0 0 2px #1f477d; /* Blue outline */
  border-radius: 5px;
  padding: 10px;
  color: #1f477d; /* Blue text color */
  font-weight: bold; /* Bold text */
}

.chat-box {
  display: flex;
  height: 75vh;
  flex-direction: column;
}

.modal-input  {
  width:20vw;
  padding-block: 0px;
  padding-inline: 0px;
}

.modal-submit {
  margin: 10px 0px 10px 0px;
  background-color: #1F477D;
  border-radius:5px;
  min-width:20vw;
}

.modal-button-container {
  display: flex;
  justify-content: center; /* Aligns button to the right */
  /* width: 90%;  */
}

.generate-modal-submit {
  width: 100px; /* Example width */
  height: 40px; /* Example height */
  background-color: #1F477D; /* Example background color */
  color: white; /* Text color */
  border: none;
  border-radius:5px;
  cursor: pointer;
  margin:5px;
}

.grey-button {
  background-color: #D1D5DB; /* Grey background color */
}

.generate-modal-submit p {
  margin: 0; /* Remove margin from <p> to align text properly */
}

.document-type-button {
  background-color: white; /* Default background */
  color: black; /* Text color */
  border: 1px solid #1F477D; /* Border color */
  padding: 10px 20px; /* Padding */
  margin: 5px; /* Margin */
  cursor: pointer; /* Cursor on hover */
}

.document-type-button.selected {
  background-color: #D3D3D3; /* Grey for selected */
}

multiple-file-upload-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Add other styling as needed */
}

.upload-file-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.upload-file-name {
  margin-left: 10px; /* Adjust spacing between image and file name */
}

.upload-file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  margin: 3px;
  border-radius: 4px;
  width: 100%;
}


.multiple-file-upload-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc; 
  border-radius: 5px; 
  min-width: 60vw;

}

.file-name {
  text-align: left;
  margin-right: auto; /* Ensures the name is left-aligned and the button stays to the right */
}

.remove-file-btn {
  padding: 5px 10px;
  background-color: #f44336; /* Red color for remove button */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.select-file-btn {
  padding: 5px 10px;
  background-color: #1F477D; /* Red color for remove button */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.view-file-btn {
  padding: 5px 10px;
  background-color: #D1D5DB; /* Red color for remove button */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}



.input-wrapper input,
.input-wrapper textarea {
  width: 100%; /* Makes inputs take the full width of their parent container */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  margin-bottom: 20px; /* Adds space between each input */
}

.input-wrapper textarea {
  height: 200px; /* Makes the textarea considerably longer */
}

.input-wrapper {
  width: 90%; /* Example: Adjust according to your modal's width */
  margin: auto; /* Centers the wrapper if the modal is wider */
}

.source-card {
 cursor:pointer;
 display:flex;
 align-items: start;
 flex-direction: column;
 margin:2px;
 background-color:#E5E7EB;

}

.general-select-btn {
  padding: 5px 10px;
  background-color: #D1D5DB; /* Red color for remove button */
  margin:10px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.selected-file-card {
  border: 2px solid #1F477D; /* Adjust the styling as needed */
}


.cs-message__content {
  background-color: inherit !important;
  padding: 10px; /* Optional: Add padding for better spacing */
}

.message-box-dark {
  background-color: #FFFFFF;
  /* border-radius:10px; */
  border-top: 1px solid #E5E7EB; /* Line at the top of each message */
  border-bottom: 1px solid #E5E7EB; /* Line at the bottom of each message */
  padding:10px;
}

.message-box-light {
  /* background-color: #1DC989; */
  /* border-radius:10px; */
  border-top: 1px solid #E5E7EB; /* Line at the top of each message */
  border-bottom: 1px solid #E5E7EB; /* Line at the bottom of each message */
  padding:10px;
}

.cs-main-container{
  border: none !important;
  background-color: inherit !important;
}

.cs-chat-container{
  border: none !important;
  background-color: inherit !important;
}

/* .cs-button {
  color:#1DC989 !important
} */

.cs-button:disabled {
  color:#FFFFFF !important
}

.cs-message-input {
  background-color: inherit !important;
}


.cs-message-input__content-editor {
  background-color: white !important;
  font-family: inherit !important;
    border: 1px solid #E5E7EB; /* Added border for outlined box */

}

.cs-message-list {
  background-color: inherit !important;
  
}


.cs-message-input__content-editor-wrapper {
  background-color: white !important;
}


.centered {
  display: flex;
  justify-content: center;
}


.cs-typing-indicator__dot {
  background-color:#1F477D !important;
}

.cs-message-list .cs-typing-indicator {
  position: relative !important;
}


.cs-message-input__tools:last-child .cs-button:last-child {
  color: #1F477D !important;
}

.ps__thumb-y {
  background-color: #1F477D !important;
}

.green-button {
  width: 90%; 
  height: 60%; 
  background-color: #E8F5E9; 
  border: 0px solid #00FF00; 
  border-radius: 20px; 
  color: #047857;
}

.main-button {
  max-width: 1000px; 
  height: 60%; 
  background-color: #1F477D; 
  border: 0px solid #1F477D; 
  border-radius: 5px; 
  color: #FFFFFF;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.unfilled-button {
  max-width: 1000px; 
  height: 60%; 
  border: 0px solid #FEE2E2; 
  border-radius: 5px; 
  color: #EF4444;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.unfilled-not-neg-button {
  max-width: 1000px; 
  height: 60%; 
  border: 0px solid #FEE2E2; 
  border-radius: 5px; 
  color: #1F477D;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-bar {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Pushes the button to the right */
  width: 100%; /* Ensures the bar takes the full width */
  height: 50px;
}

.upload-container {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  position: relative;
}

.input-container {
  display: flex;
  margin-bottom: 16px;
}

.file-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px
}

.tiles-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  justify-content: center; /* Center the grid items horizontally */
}

.tile {
  width: 100%;
  height: 150px;
  border: 2px solid #E5E7EB;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: bold;
  background: #ffffff;
}

.upload-area {
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 40px;
  text-align: center;
  cursor: pointer;
  width: 90%;
  margin: 20px auto;
  transition: border-color 0.3s;
}

.upload-area:hover {
  border-color: #888;
}

.tile-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 20px;
  height: 100%;
  background-color: #FFFFFF;
  border: 0px solid #E5E7EB;
}

.file-info {
  padding: 5px;
  border-radius: 10px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #E5E7EB;
}

.file-info button {
  /* background-color: #f44336; */
  color: #000000;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.toggle-button {
  padding: 5px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  align-items: center;
  justify-content: center;
}

.toggle-button.on {
  background-color: #ECFDF5;
  color: #047857;
  border: 1px solid #D1FAE5;
}

.toggle-button.off {
  background-color: #FEE2E2;
  color: #EF4444;
  border: 1px solid #FEE2E2;
}

.toggle-slider {
  position: relative;
  width: 60px;
  height: 34px;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  cursor: pointer;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

input[type='checkbox'] {
  display: none;
}

input[type='checkbox']:checked + .slider {
  background-color: #047857;
}

input[type='checkbox']:checked + .slider:before {
  transform: translateX(26px);
}

.delete-button {
  background: none;
  border: none;
  color: #EF4444;
  cursor: pointer;
  font-size: 24px;
  margin-left: 10px;
}

.delete-button i {
  pointer-events: none;
}

.side-by-side-container {
  display: flex;
  align-items: center; /* Align items vertically centered */
  gap: 10px; /* Adjust the gap between items */
}

.header-within-container {
  margin-top: 10px;
  margin-bottom: -8px;
}

.automation-item {
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.sidebar {
  width: 400px;
  height: 100vw;
  margin-top: -20px;
  margin-left: 20px;
  border-left: 2px solid #E5E7EB;
  background-color: #F9FAFB;
  transition: transform 0.3s ease-in-out;
}

.sidebar-open {
  transform: translateX(0);
}

.sidebar-element {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 2px solid #E5E7EB;
}

.icon-text-container {
  display: flex;
  align-items: center;
}

.sidebar-icon {
  margin-right: 10px;
}

.sidebar-button {
  background-color: #1F477D;
  color: white;
  border-radius: 5px;
  padding: 10px;
}

.status-button {
  padding: 5px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  align-items: center;
  justify-content: center;
}

.status-button.on {
  background-color: #ECFDF5;
  color: #047857;
  border: 1px solid #D1FAE5;
}

.status-button.off {
  background-color: #FFFBEB;
  color: #B45309;
  border: 1px solid #FEF3C7;
}

.status-button.maybe {
  background-color: #FEE2E2;
  color: #EF4444;
  border: 1px solid #FEE2E2;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
  padding: 40px;
  text-align: center;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #1F477D; /* Blue */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.document-type-container {
  display: flex;
  flex-wrap: wrap;
  max-height: 100%; /* Adjust the height as needed */
  overflow-y: auto;
  width: 80%; /* Ensure the container takes up the full width */
}

.document-type-button {
  flex: 0 1 calc(20% - 10px); /* Ensure each item takes up 20% of the width minus margin */
  box-sizing: border-box;
  margin: 5px; /* Add some margin for spacing */
  height: 100px; /* Ensure consistent height */
}

.checkmark-icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: green;
}

.fixed-bottom-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; /* Ensure it appears above other elements */
}

.message-input input::placeholder {
  text-align: left; /* Align placeholder text to the left */
}